<template>
    <div>
        <v-container class="mycontainer">
            <v-card>
                <v-card-title>
                    {{mortgages.length}} Applications
                </v-card-title>
                <v-card-title>
                    <v-text-field
                            v-model="search"
                            label="Search"
                            single-line
                    ></v-text-field>

                    <v-spacer></v-spacer>
                    <v-select
                            :items="['mortgage','nhf']"
                            v-model="type"
                            item-text="status"
                            item-value="status"
                            label="Filter By type"
                    ></v-select>
                </v-card-title>
                <v-data-table :loading="apiloading" loading-text="Loading... Please wait"
                              :headers="mortgage_headers"
                              :items="mortgages"
                              :items-per-page="10"
                              :search=search
                              class="elevation-1"
                >

                    <template v-slot:item.firstname="{ item }">
                        {{item.firstname}} {{item.lastname}}
                    </template>
                    <template v-slot:item.loan_amount="{ item }">
                        {{item.loan_amount| currency}}
                    </template>
                    <template v-slot:item.loan_tenure="{ item }">
                        {{item.loan_tenure}}
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-icon  medium class="mr-2" @click="showSummary(item.slug)">mdi-eye-circle-outline</v-icon>
                    </template>
                    <!--                    <template v-slot:expanded-item="{item }">-->
                    <!--                        <div style="width: 100%;min-height:300px;">-->
                    <!--                                <summary-card :item="item"></summary-card>-->
                    <!--                        </div>-->
                    <!--                    </template>-->
                </v-data-table>
            </v-card>
        </v-container>
    </div>

</template>
<script>
    import {loadingMixin} from "@/mixins/Loading";
    import {mortgageMixin} from "../../../mixins/mortgage";

    export default {
        mixins:[loadingMixin,mortgageMixin],
        data() {
            return {
                search:'',
                type:'',
                expanded: [],
                singleExpand: true,
                mortgage_headers:[
                    { text: 'Date', value: 'created_at' },
                    { text: 'Type', value: 'type' },
                    {text: 'Ref No',align: 'left',sortable: false,value: 'app_ref' },
                    { text: 'Customer', value: 'firstname' },
                    { text: 'Loan Amount', value: 'loan_amount' },
                    { text: 'Tenure(Y)', value: 'loan_tenure' },
                    {text:'Action',value: 'action', sortable: false },
                    // { text: 'Action', value: 'data-table-expand', sortable: false },
                ],
            }
        },
        computed: {
            mortgages() {
                return this.$store.state.mortgage_module.verified_mortgage
            }

        },
        methods: {
            getMortgage() {
                this.$store.dispatch("fetchMortgageByStatusAction",'verify');
            }
        },
        created() {
            this.getMortgage();
        }
    }
</script>
<style>

</style>