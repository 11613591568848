<template>
  <v-dialog v-model="show" max-width="420px">
    <v-card>
      <v-card-title class="headline">{{ modalTitle }}</v-card-title>

        <v-card-text>{{ modalBody }}</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            :disabled="apiloading"
            @click="$emit('close-modal')"
          >
            {{ cancelText || 'Cancel' }}
          </v-btn>

          <v-btn
            :loading='apiloading'
            :disabled="apiloading"
            color="green darken-1"
            text
            @click="onConfirm"
          >
            {{ confirmText || 'Continue' }}
          </v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { loadingMixin } from '../../../mixins/Loading';

export default {
  props: [
    'show', 'modalTitle', 'modalBody', 'confirmText', 'cancelText', 'onConfirm'
  ],
  mixins: [loadingMixin]
}
</script>
