<template>
  <v-card>
    <pdf
      :src='fileURL'
      @num-pages='pageCount = $event'
      @page-loaded='currentPage = $event'
    />
  </v-card>
</template>
<script>
import pdf from 'vue-pdf';

export default {
  props: ['fileURL'],
  components: {
    pdf
  },
  data() {
    return {
      pageCount: 0,
      currentPage: 0
    };
  },

}
</script>
