<template>
  <v-dialog v-model="show" max-width="420px">
    <v-card>
      <v-card-title class="headline">{{ modalTitle }}</v-card-title>

        <v-card-text>{{ modalBody }}</v-card-text>
        <v-spacer />

        <v-form>
          <v-textarea
            v-model="reason"
            :placeholder="placeholder"
            rows="1"
            :auto-grow="true"
            :clearable="true"
            required
          />
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            :disabled="apiloading"
            text
            @click="$emit('close-modal')"
          >
            {{ cancelText || 'Cancel' }}
          </v-btn>

          <v-btn
            :loading='apiloading'
            :disabled="apiloading"
            color="green darken-1"
            text
            @click="onDecline"
          >
            {{ confirmText || 'Continue' }}
          </v-btn>
        </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import { loadingMixin } from '../../../mixins/Loading';

export default {
  props: ['show', 'modalTitle', 'modalBody', 'confirmText', 'cancelText', 'onConfirm', 'placeholder'],
  data() {
    return {
      reason: ''
    };
  },
  methods: {
    onDecline() {
      this.onConfirm({ reason: this.reason });
    }
  },
  mixins: [loadingMixin]
}
</script>

<style scoped>
  form {
    padding: 10px 24px
  }
</style>
