export const printJSStyle = `

* {
  box-sizing: border-box;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.fp-mortgage-landing-preview-card {
    font-size: 20px;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid #a9a5a5;
    position: relative;
    border-radius: 5px;
    page-break-inside: avoid;
    height: fit-content;
}

.fp-mortgage-landing-preview-card::before {
    content: attr(data-label);
    display: block;
    position: absolute;
    top: -5px;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    background-color: inherit;
    color: #2e2e2e;
    /* color: #00b1ab; */
    padding: 0 5px;
}

.fp-mortgage-application-preview .fp-mortgage-landing-preview-card span {
    color: #2e2e2e;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

.fp-personal-info-form span {
    font-size: 15px;
    color: #2e2e2e;
    line-height: 2px;
    font-weight: 400;
}

.details-box {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 10px;
}

.details-box > div {
    padding-right: 20px;
}

.details-box > div > p {
    display: flex;
    flex-wrap: wrap;
}

.details-box th {
    text-align: left;
}

.details-box > div span {
    font-weight: 600;
}

.details-box > div:not([class^='c1-']) {
    padding-left: 20px !important;
}

.c1-13 {
    grid-column: 1 / 13;
}

.c1-4 {
    grid-column: 1 / 4;
}

.c4-7 {
    grid-column: 4 / 7;
}

.c4-10 {
    grid-column: 4 / 10;
}

.c7-10 {
    grid-column: 7 / 10;
}

.c10-13 {
    grid-column: 10 / 13;
}

.c1-7 {
    grid-column: 1 / 7;
}

.c7-13 {
    grid-column: 7 / 13;
}

.c1-5 {
    grid-column: 1 / 5;
}

.c5-13 {
    grid-column: 5 / 13;
}

.c5-9 {
    grid-column: 5 / 9;
}

.c9-13 {
    grid-column: 9 / 13;
}
`;
