<template>
  <v-card elevation="1">
    <v-toolbar color class="no-print">
      <v-toolbar-title>{{ mortgage.app_ref }} Details</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <!--                            <v-btn dark text @click="dialog = false">Save</v-btn>-->
        <v-btn text color="black" @click="print">Print</v-btn>
        <v-menu bottom open-on-hover origin="center center" transition="scale-transition">
          <template v-slot:activator="{ on }">
            <v-btn text color="black" v-on="on">Actions</v-btn>
          </template>

          <v-list>
            <v-list-item v-for="(item, i) in action_dropdown" :key="i" @click='item.onClick'>
              <v-list-item-title v-if="mortgage.mortgage_status_name != item.text">{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-btn icon color="black" @click="$emit('close-modal')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <section class="container">
      <div class="container" id="to-print">
        <div class="row fp-mortgage-application">
          <div class="col-md-12">
            <div class="fp-mortgage-landing-page-with-nhanla">
              <div class="row">
                <approve-dialog
                  :confirmText="'Proceed'"
                  :cancelText="'Close'"
                  :show="showApprovalDialog"
                  :modalTitle="'Approve Application'"
                  @close-modal="showApprovalDialog = false"
                  :modalBody="'Please confirm approval of this application'"
                  :onConfirm='approveApplication'
                />
                <approve-dialog
                  :confirmText="'Proceed'"
                  :cancelText="'Close'"
                  :show="showVerifyApplicationDialog"
                  :modalTitle="'Approve Application'"
                  @close-modal="showVerifyApplicationDialog = false"
                  :modalBody="'Please confirm verification of this application'"
                  :onConfirm='verifyApplication'
                />

                <decline-dialog
                  :confirmText="'Proceed'"
                  :cancelText="'Close'"
                  :show="showDeclineDialog"
                  :placeholder="'Reason for decline'"
                  :modalTitle="'Decline Application?'"
                  @close-modal="showDeclineDialog = false"
                  :modalBody="'Please confirm decline of this application'"
                  :onConfirm='declineApplication'
                />

                <decline-dialog
                  :confirmText="'Proceed'"
                  :cancelText="'Close'"
                  :show="showQueryApplicationDialog"
                  :modalTitle="'Query Application?'"
                  :placeholder="'What is missing?'"
                  @close-modal="showQueryApplicationDialog = false"
                  :modalBody="'Please confirm query of this application'"
                  :onConfirm='queryApplication'
                />

                <div class="col-md-12 fp-personal-info-form">
                  <div
                    v-if="mortgage.lender"
                    :style="{
                backgroundImage: 'url(' + mortgage.lender.lender_logo + ')'
              }"
                    class="lender_logo2"
                  ></div>
                  <h2 v-if="mortgage.lender">{{ mortgage.lender.lender_name }}</h2>
                  <span>
                    <b>REF:</b>
                    {{ mortgage.app_ref }} | Mortgage Application
                  </span>
                  <br />

                  <span>Date: {{ mortgage.created_at | formatDate }}</span>

                  <div class="fp-mortgage-application-preview">
                    <div data-label="Personal Information" class="fp-mortgage-landing-preview-card">
                      <div class="details-box">
                        <div class="c1-13"></div>
                        <div class="c1-4">
                          <span>Title</span>
                          <p>Mr</p>
                        </div>
                        <div class="c4-7">
                          <span>First Name</span>
                          <p>{{ mortgage.firstname }}</p>
                        </div>
                        <div class="c7-10">
                          <span>Last Name</span>
                          <p>{{ mortgage.lastname }}</p>
                        </div>
                        <div class="c10-13">
                          <span>Middle Name</span>
                          <p>{{ mortgage.middlename }}</p>
                        </div>

                        <div class="c1-4">
                          <span>Address</span>
                          <p>{{ mortgage.address }}</p>
                        </div>

                        <div class="c4-10">
                          <span>Email Address</span>
                          <p>{{ mortgage.email }}</p>
                        </div>

                        <div class="c10-13">
                          <span>Phone Number</span>
                          <p>{{ mortgage.phone }}</p>
                        </div>
                        <div class="c1-4">
                          <span>Date of Birth</span>
                          <p>{{ mortgage.dob }}</p>
                        </div>

                        <div class="c4-7">
                          <span>Sex</span>
                          <p>{{ mortgage.sex }}</p>
                        </div>

                        <div class="c7-10">
                          <span>Place of Birth</span>
                          <p>{{ mortgage.place_of_birth }}</p>
                        </div>

                        <div class="c10-13">
                          <span>Nationality</span>
                          <p>{{ mortgage.nationality }}</p>
                        </div>

                        <div class="c1-4">
                          <span>State of Origin</span>
                          <p>{{ mortgage.state_of_origin }}</p>
                        </div>

                        <div class="c4-10">
                          <span>Highest Educational Qualification</span>
                          <p>{{ mortgage.highest_education }}</p>
                        </div>

                        <div class="c10-13">
                          <span>Profession</span>
                          <p>{{ mortgage.profession }}</p>
                        </div>
                      </div>
                    </div>

                    <div
                      data-label="Other Personal Information"
                      class="fp-mortgage-landing-preview-card mt40"
                    >
                      <div class="details-box">
                        <div class="c1-13"></div>

                        <div class="c1-4">
                          <span>Current Apartment Status</span>
                          <p>{{ mortgage.current_apartment_status }}</p>
                        </div>

                        <div class="c4-7">
                          <span>Others</span>
                          <p>-</p>
                        </div>

                        <div class="c7-10">
                          <span>Annual Rent</span>
                          <p>{{ mortgage.annual_rent_value | currency }}</p>
                        </div>

                        <div class="c10-13">
                          <span>Marital Status</span>
                          <p>{{ mortgage.marital_status }}</p>
                        </div>

                        <div class="c1-4">
                          <span>Spouse’s Name</span>
                          <p>{{ mortgage.spouse_name }}</p>
                        </div>

                        <div class="c4-7">
                          <span>Spouse’s Address</span>
                          <p>{{ mortgage.spouse_address }}</p>
                        </div>

                        <div class="c7-10">
                          <span>Spouse’s Employer</span>
                          <p>{{ mortgage.spouse_employer }}</p>
                        </div>

                        <div class="c10-13">
                          <span>No of Years Employed</span>
                          <p>{{ mortgage.spouse_work_experience }}</p>
                        </div>

                        <div class="c1-4">
                          <span>Profession of Spouse</span>
                          <p>{{ mortgage.spouse_profession }}</p>
                        </div>

                        <div class="c4-7">
                          <span>Spouse's Annual Income</span>
                          <p>{{ mortgage.spouse_annual_income }}</p>
                        </div>

                        <div class="c1-13">
                          <span>Children/Other Dependents:</span>
                          <table class="table rtable w-100">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Age</th>
                                <th>Relationship</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(child, i) in JSON.parse(mortgage.children)" :key="i">
                                <td>{{ child.name }}</td>
                                <td>{{ child.age }}</td>
                                <td>{{ child.relationship }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div class="c1-5">
                          <span>Next-of-Kin’s Name</span>
                          <p>{{ mortgage.next_of_kin_name }}</p>
                        </div>

                        <div class="c5-9">
                          <span>Next-of-Kin’s Relationship</span>
                          <p>{{ mortgage.next_of_kin_relationship }}</p>
                        </div>

                        <div class="c9-13">
                          <span>Next-of-Kin’s Age</span>
                          <p>{{ mortgage.next_of_kin_age }}</p>
                        </div>

                        <div class="c1-13">
                          <span>Next-of-Kin Address</span>
                          <p>{{ mortgage.next_of_kin_address }}</p>
                        </div>
                      </div>
                    </div>

                    <div
                      data-label="Employment Information"
                      class="fp-mortgage-landing-preview-card mt40"
                    >
                      <div class="details-box">
                        <div class="c1-12"></div>
                        <div class="c1-5">
                          <span>Company Name</span>
                          <p>{{ mortgage.employer_name }}</p>
                        </div>
                        <div class="c5-13">
                          <span>Company Email Address</span>
                          <p>{{ mortgage.employer_email }}</p>
                        </div>
                        <div class="c1-5">
                          <span>Employer Phone No</span>
                          <p>{{ mortgage.employer_phone }}</p>
                        </div>
                        <div class="c1-13">
                          <span>
                            Address of Employer (If less than three years, give name
                            of previous employer)
                          </span>
                          <p>{{ mortgage.employer_address }}</p>
                        </div>
                      </div>
                    </div>

                    <div
                      data-label="Financial Information"
                      class="fp-mortgage-landing-preview-card mt40"
                    >
                      <div class="details-box">
                        <div class="c1-13"></div>
                        <div class="c1-7">
                          <span>
                            Employment Income (
                            <i>Total Annual Pay</i>)
                          </span>
                          <p>{{ mortgage.total_annual_pay | currency }}</p>
                        </div>
                        <div class="c7-13">
                          <span>
                            Employment Income (
                            <i>Monthly Gross Pay</i>)
                          </span>
                          <p>{{ mortgage.monthly_gross_pay | currency }}</p>
                        </div>
                        <div class="c1-7">
                          <span>
                            Employment Income (
                            <i>Monthly Net Pay</i>)
                          </span>
                          <p>{{ mortgage.monthly_net_pay | currency }}</p>
                        </div>
                        <div class="c7-13">
                          <span>Estimated Monthly Living Expenses</span>
                          <p>{{ mortgage.monthly_expenses | currency }}</p>
                        </div>

                        <div class="c1-13">
                          <span>Other Source(s) of Income</span>
                          <table class="table rtable w-100">
                            <thead>
                              <tr>
                                <th>Source</th>
                                <th>Amount/Year (N)</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(income, i) in JSON.parse(
                            mortgage.other_source_of_income
                          )"
                                :key="i"
                              >
                                <td>{{ income.source }}</td>
                                <td>{{ income.amount | currency }}</td>
                              </tr>
                            </tbody>
                          </table>
                          <span>Total: -</span>
                        </div>
                        <div class="c1-13">
                          <span>Outstanding loans (if any)</span>
                          <table class="table rtable w-100">
                            <thead>
                              <tr>
                                <th>Lender Name</th>
                                <th>Loan Type</th>
                                <th>Outstanding Amount</th>
                                <th>Frequency</th>
                                <th>Existing Payment</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(child, i) in JSON.parse(
                            mortgage.outstanding_loans
                          )"
                                :key="i"
                              >
                                <td>{{ child.lender_name }}</td>
                                <td>{{ child.loan_type }}</td>
                                <td>{{ child.outstanding_amount }}</td>
                                <td>{{ child.loan_frequency }}</td>
                                <td>{{ child.existing_payment }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div
                      data-label="Loan Information"
                      class="fp-mortgage-landing-preview-card mt40"
                    >
                      <div class="details-box">
                        <div class="c1-13"></div>
                        <div class="c1-4">
                          <span>Equity Contribution</span>
                          <p>{{ mortgage.down_payment | currency }}</p>
                        </div>
                        <div class="c4-7">
                          <span>Loan Amount</span>
                          <p>{{ mortgage.loan_amount | currency }}</p>
                        </div>
                        <div class="c7-10">
                          <span>Loan Tenure</span>
                          <p>{{ mortgage.loan_tenure }}</p>
                        </div>
                        <div class="c10-13">
                          <span>Employer's guarantee</span>
                          <p>{{ mortgage.have_employer_guarantor }}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <!--                                        <MultiStepFormButtons-->
                    <!--                                                type='Mortgage-Application'-->
                    <!--                                                handleBackClick={this.props.goToPreviousComponent}-->
                    <!--                                        />-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import ApproveApplicationDialog from "./ApproveApplicationDialog";
import DeclineApplicationDialog from './DeclineApplicationDialog';
import { MortgageApiSource } from '../../../services/MortgageApiService';
import toast from '../../../services/ToastService';

export default {
  props: ["mortgage", "print"],
  data() {
    return {
      showApprovalDialog: false,
      showDeclineDialog: false,
      showQueryApplicationDialog: false,
      showVerifyApplicationDialog: false,
      mortgageService: new MortgageApiSource(),
    };
  },
  computed: {
    ...mapState({
      userRole(state) { return state.authmodule.user.role; }
    }),
    action_dropdown() {
      let actions = [
        { text: "Verify", action: "Verify", onClick: () => this.showVerifyApplicationDialog = true },
        { text: "Decline", action: "Declined", onClick: () => this.showDeclineDialog = true },
        { text: "Query Application", action: "Query", onClick: () => this.showQueryApplicationDialog = true },
      ];

      if (this.userRole.toLowerCase() === 'approval') {
        if (this.mortgage.mortgage_status_name.toLowerCase() !== 'verified')  actions = [];
        else {
          actions.pop();
          actions[0] = { text: 'Approve', action: 'Approve', onClick: () => this.showApprovalDialog = true };
        }
      }

      return actions;
    },
  },
  components: {
    "approve-dialog": ApproveApplicationDialog,
    "decline-dialog": DeclineApplicationDialog
  },
  methods: {
    async verifyApplication() {
      try {
        const res = await this.mortgageService.verifyMortgage(this.mortgage.slug);
        if (res.status === 200) this.handleApplicationStatusChangeSuccess('Application verified');
      } catch (error) {} finally { this.showVerifyApplicationDialog = false; }
    },
    async approveApplication() {
      try {
        const res = await this.mortgageService.approvedMortgage(this.mortgage.slug);
        if (res.status === 200) this.handleApplicationStatusChangeSuccess('Application approved');
      } catch (error) {} finally { this.showApprovalDialog = false; }
    },
    async declineApplication(payload) {
      try {
        const res = await this.mortgageService.declinedMortgage(payload, this.mortgage.slug);
        if (res.status === 200) this.handleApplicationStatusChangeSuccess('Application declined');
      } catch (error) {} finally { this.showDeclineDialog = false; }
    },
    async queryApplication(payload) {
      try {
        const res = await this.mortgageService.queryMortgage(this.mortgage.slug, payload);
        if (res.status === 200) this.handleApplicationStatusChangeSuccess('Query submitted');
      } catch (error) {} finally { this.showQueryApplicationDialog = false; }
    },
    handleApplicationStatusChangeSuccess(message) {
      toast.success(message);
      const timeout = setTimeout(() => {
         this.$router.go();
         clearTimeout(timeout);
      }, 1500);
    }
  }
};
</script>

<style scoped>
.details-box {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.c1-13 {
  grid-column: 1 / 13;
}

.c1-4 {
  grid-column: 1 / 4;
}

.c4-7 {
  grid-column: 4 / 7;
}

.c4-10 {
  grid-column: 4 / 10;
}

.c7-10 {
  grid-column: 7 / 10;
}

.c10-13 {
  grid-column: 10 / 13;
}

.c1-7 {
  grid-column: 1 / 7;
}

.c1-5 {
  grid-column: 1 / 5;
}

.c5-9 {
  grid-column: 5 / 9;
}

.c5-13 {
  grid-column: 5 / 13;
}

.c9-13 {
  grid-column: 9 / 13;
}

.c7-13 {
  grid-column: 7 / 13;
}
</style>
