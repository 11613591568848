<template>
  <div>
    <v-container class="mycontainer2">
      <div class="loader text-center" v-if="apiloading">
        <v-icon size="80">mdi-spin</v-icon>
      </div>
      <div class="fp-dashboard-section" v-if="!apiloading">
        <div class="row">
          <div class="col-md-6"></div>
          <div class="col-md-6 text-center">

            <v-btn class="ma-2 float-right clearfix" outlined color="indigo" @click="viewDetails">
              <v-icon>mdi-eye</v-icon>View Details
            </v-btn>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-xs-12 fp-mortgage-application-lender-info-wrapper">
            <div class="fp-mortgage-application-loan-value text-center">
              <p class="text-center">Loan Amount</p>
              <h2>{{ mortgage.loan_amount | currency }}</h2>
            </div>

            <div class="fp-mortgage-application-lender-info">
              <div class="fp-mortgage-lender-company-info">
                <h2>Client's Info</h2>
                <v-icon size="30" @click="viewDetails()">mdi-eye-outline</v-icon>
              </div>
              <div class="fp-mortgage-lender-company-info-wrap">
                <v-icon
                  class="fp-mortgage-lender-company-info-icon"
                  color="grey"
                  size="30"
                >mdi-account-outline</v-icon>
                <div class="fp-mortgage-lender-company-email span-parent">
                  <span>Name</span>
                  <h4>{{ mortgage.firstname }} {{ mortgage.lastname }}</h4>
                </div>
              </div>

              <div class="fp-mortgage-lender-company-info-wrap">
                <v-icon
                  class="fp-mortgage-lender-company-info-icon"
                  color="grey"
                  size="30"
                >mdi-email-outline</v-icon>
                <div class="fp-mortgage-lender-company-email span-parent">
                  <span>Email Address</span>
                  <h4>{{ mortgage.email }}</h4>
                </div>
              </div>

              <div class="fp-mortgage-lender-company-info-wrap">
                <v-icon
                  class="fp-mortgage-lender-company-info-icon"
                  color="grey"
                  size="30"
                >mdi-phone-outline</v-icon>
                <div class="fp-mortgage-lender-company-phone span-parent">
                  <span>Phone Number</span>
                  <h4>{{ mortgage.phone }}</h4>
                </div>
              </div>

              <div class="fp-mortgage-lender-company-info-wrap fp-border-line">
                <v-icon
                  class="fp-mortgage-lender-company-info-icon"
                  color="grey"
                  size="30"
                >mdi-map-marker-outline</v-icon>
                <div class="fp-mortgage-lender-company-address span-parent">
                  <span>Client Address</span>
                  <h4>{{ mortgage.address }}</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8 col-xs-12">
            <div class="fp-mortgage-application-loan-info-display">
              <div class="fp-mortgage-application-reference-no-loan-status">
                <div class="fp-mortgage-application-reference-no span-parent">
                  <span>Reference</span>
                  <h4>{{ mortgage.app_ref }}</h4>
                </div>
                <div class="fp-mortgage-application-date span-parent">
                  <span>Application Submitted</span>
                  <h4>{{ mortgage.created_at | formatDate }}</h4>
                </div>
                <div class="fp-mortgage-application-status span-parent">
                  <span>Loan Status</span>
                  <h4>{{ mortgage.mortgage_status_name }}</h4>
                </div>
              </div>
              <div class="fp-mortgage-loan-amount-repayment-interest-rate">
                <div class="fp-mortgage-loan-amount-wrap span-parent">
                  <h2>{{ mortgage.down_payment | currency }}</h2>
                  <span>Down Payment</span>
                </div>
                <div class="fp-mortgage-repayment span-parent">
                  <h2>
                    {{ mortgage.loan_tenure ? mortgage.loan_tenure : "Null" }}
                    {{ mortgage.loan_tenure ? "Years" : "" }}
                  </h2>
                  <span>Repayment period</span>
                </div>
                <div class="fp-mortgage-loan-interest-rate span-parent">
                  <h2>
                    {{ mortgage.loan_rate ? mortgage.loan_rate : "Null" }}
                    {{ mortgage.loan_rate ? "%" : "" }}
                  </h2>
                  <span>Interest rate</span>
                </div>
              </div>
<!--              <div class="fp-mortgage-loan-amount-repayment-interest-rate">-->
<!--                <div class="fp-mortgage-loan-amount-wrap span-parent">-->
<!--                  <h4>Borrower Affordability & Eligibility</h4>-->
<!--&lt;!&ndash;                  <span>Down Payment</span>&ndash;&gt;-->
<!--                </div>-->
<!--                <div class="fp-mortgage-repayment span-parent">-->
<!--                  <v-btn class="ma-2 float-right clearfix" outlined color="indigo" @click="viewDetails">-->
<!--                    <v-icon>mdi-eye</v-icon>View Status-->
<!--                  </v-btn>-->

<!--                </div>-->

<!--              </div>-->
              <div class="fp-mortgage-loan-more-information">
                <div class="fp-mortgage-loan-more-information-right">
                  <!--                                <div class='fp-mortgage-loan-type-application-wrap'>-->
                  <!--                                    <div class='fp-mortgage-loan-type span-parent'>-->
                  <!--                                        <span>Loan Type</span>-->
                  <!--                                        <h4>-->

                  <!--                                        </h4>-->
                  <!--                                    </div>-->
                  <!--                                    <div class='fp-mortgage-loan-application-type'>-->
                  <!--                                        <p> Single Application</p>-->
                  <!--                                    </div>-->
                  <!--                                </div>-->
                  <div class="fp-mortgage-loan-property-address-wrap span-parent">
                    <span>Loan Type</span>
                    <div class="row" style="margin-top:-10px">
                      <div class="col-md-6"> <h4 class="f">{{ mortgage.type }}</h4></div>
                      <div class="col-md-6">
                        <v-btn class="float-right" outlined color="success" @click="openAffordModal()">
                          <v-icon>mdi-eye</v-icon>View Affordability Result
                        </v-btn>
                      </div>
                    </div>


                  </div>
                  <div class="fp-mortgage-loan-property-address-wrap span-parent">
                    <span>Property Address</span>
                    <h4>{{ mortgage.property_address }}</h4>
                  </div>
                  <div class="fp-mortgage-loan-property-address-wrap span-parent">
                    <span>Property Type</span>
                    <h4>
                      {{ mortgage.property_type }} |
                      {{ mortgage.property_bedroom }} Bedroom
                    </h4>
                  </div>

                  <!--                                <div class='fp-mortgage-loan-property-address-wrap span-parent'>-->
                  <!--                                    <span>Down Payment</span>-->
                  <!--                                    <h4>-->
                  <!--                                       DownPayment-->
                  <!--                                    </h4>-->
                  <!--                                </div>-->
                  <div class="fp-mortgage-loan-property-address-wrap span-parent">
                    <span>Property Info</span>
                    <h4 class="text-break">{{ mortgage.property_info }}</h4>
                  </div>
                  <div class="fp-mortgage-loan-property-address-wrap span-parent">
                    <span>Comments</span>
                    <h4 class="text-break">
                      {{
                      mortgage.verify_comment
                      ? mortgage.verify_comment
                      : "This application is still pending and verification process is still going on"
                      }}
                    </h4>
                  </div>
                </div>
                <div class="fp-mortgage-loan-more-information-left">
                  <div class="fp-mortgage-loan-requirement-status">
                    <v-icon color="green" size="40">mdi-alert-circle-check-outline</v-icon>

                    <h4 class="text-wrap">Borrower Eligibility</h4>
                  </div>

                  <div class="fp-mortgage-loan-requirement-status">
                    <v-icon color="green" size="40">mdi-alert-circle-check-outline</v-icon>
                    <h4 class="text-wrap">All Required Documents</h4>
                  </div>

                  <div class="fp-mortgage-loan-requirement-status">
                    <v-icon color="green" size="40">mdi-alert-circle-check-outline</v-icon>
                    <h4 class="text-wrap">Borrower Verification</h4>
                  </div>

                  <div class="fp-mortgage-loan-requirement-status">
                    <v-icon color="green" size="40">mdi-alert-circle-check-outline</v-icon>
                    <h4 class="text-wrap">Property Verification</h4>
                  </div>
                </div>
              </div>
              <div class="fp-mortgage-application-client-doc">
                <div class="fp-mortgage-lender-company-info">
                  <h2>Client's Documents</h2>
                  <!--                            <v-icon size="30">mdi-eye-outline</v-icon>-->
                  <span style="font-size:20px;font-weight:bold">
                    {{
                    userdoc.length
                    }}
                  </span>
                </div>
                <div class="fp-mortgage-loan-documents-uploads-actions">
                  <div class="fp-mortgage-loan-documents-uploads-actions-right">
                    <div
                      class="fp-mortgage-loan-documents-uploads span-parent"
                      v-for="(file, i) in userdoc"
                      :key="i"
                    >
                      <document-item :key="i" :file='file' :app_ref='mortgage.app_ref' />
                    </div>

                    <!--                            <div class='fp-mortgage-loan-documents-uploads'>-->
                    <!--                                <v-icon size="50">mdi-file-outline</v-icon>-->
                    <!--                                <span class='fp-document-badge badge badge-primary'>-->
                    <!--                              P-SLIP-->
                    <!--                            </span>-->
                    <!--                            </div>-->

                    <!--                            <div class='fp-mortgage-loan-documents-uploads'>-->
                    <!--                                <v-icon size="50">mdi-file-outline</v-icon>-->
                    <!--                                <span class='fp-document-badge badge badge-primary'>-->
                    <!--                              TITLE-->
                    <!--                            </span>-->
                    <!--                            </div>-->

                    <!--                            <div class='fp-mortgage-loan-documents-uploads'>-->
                    <!--                                <v-icon size="50">mdi-file-outline</v-icon>-->
                    <!--                                <span class='fp-document-badge badge badge-primary'>-->
                    <!--                              RECEIPT-->
                    <!--                            </span>-->
                    <!--                            </div>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-container>
<!--    affordabiity modal-->
    <v-row justify="center">
      <v-dialog v-model="affordability_modal" persistent max-width="500">
        <v-card>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">Affordability Result</th>
                  <th class="text-left">Pass</th>
                </tr>
                </thead>
                <tbody>
                <tr >
                  <td>Maximum Loanable</td><td>{{mortgage.loan_amount | currency}}</td>
                </tr>
                <tr >
                  <td>Property Value</td><td>{{mortgage.property_value | currency}}</td>
                </tr>
                <tr >
                  <td>Equity Deposit</td><td>{{mortgage.down_payment | currency}}</td>
                </tr>
                <tr >
                  <td>Loan Amount</td><td>{{mortgage.loan_amount | currency}}</td>
                </tr>
                <tr >
                  <td>Monthly Repayment</td><td></td>
                </tr>
                <tr >
                  <td>Tenor</td><td>{{mortgage.tenure}}</td>
                </tr>
                <tr >
                  <td>Monthly Salary</td><td>{{mortgage.monthly_net_pay | currency}}</td>
                </tr>
                <tr >
                  <td>Qualifying Salary</td><td>{{mortgage.monthly_net_pay * 33/100 | currency}}</td>
                </tr>
                <tr >
                  <td>Age</td><td>{{mortgage.dob | calculateAge}}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="affordability_modal = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!--        View Application details Modal-->
    <v-row justify="center">
      <v-dialog v-model="detailModal" fullscreen hide-overlay transition="dialog-bottom-transition">
        <application-details @close-modal='detailModal = false' :mortgage="mortgage" :print='print' />
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { loadingMixin } from "../../../mixins/Loading";
import ApplicationDetails from './ApplicationDetails';
import DocumentListItem from './DocumentListItem';
import axios from "axios";
import printJS from "print-js";
import { printJSStyle } from '../../../utils/contants.utils';

export default {
  name: "Summary",
  mixins: [loadingMixin],
  components: {
    "application-details": ApplicationDetails,
    'document-item': DocumentListItem
  },
  data() {
    return {
      detailModal: false,
      affordability_modal:false,
      slug: this.$route.params.slug,
      docs: [],
      fileToDownload: [],
      image_index: null,
      isPdf: false,
      showpdf: false,
      pdfname: "",
      files: []
    };
  },
  watch: {
    $route(to, from) {
      this.slug = to.params.slug;
    }
  },
  computed: {
    mortgage() {
      return this.$store.state.mortgage_module.mortgage_details;
    },
    userdoc() {
      let d = this.$store.state.mortgage_module.user_doc;
      this.fileToDownload = d;
      let newfiles = [];

      if (typeof d != "string") {
        d.filter((c, i) => {
          newfiles[i] = c.filename;
          if (c.filename != null) {
            this.docs.push(c.filename);
          } // console.log(c.filename
          this.getAllFiles(newfiles);
        });
      }
      return d;
    }
  },
  methods: {
    openAffordModal(){
      this.affordability_modal =true;
    },
    showMortgageDetail() {
      this.$store.dispatch("mortgageDetailAction", this.slug);
    },
    viewDetails() {
      this.detailModal = true;
    },
    getAllFiles(urls) {
      Promise.all(
        urls.map(async url => {
          const blob = await fetch(
            `https://cors-anywhere.herokuapp.com/${url}`
          ).then(res => res.blob());
          return URL.createObjectURL(blob);
        })
      )
        .then(fls => {
          this.files = fls;
        })
        .catch(e => console.log(e));
    },
    print() {
      printJS({
        printable: "to-print",
        type: "html",
        style: printJSStyle
      });
    },
    download() {
      axios("/lender/download/" + this.slug, {
        method: "GET",
        responseType: "blob"
      })
        .then(response => {
          //Create a Blob from the PDF Stream
          const file = new Blob([response.data], { type: "application/pdf" });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          window.open(fileURL);
        })
        .catch(error => {
          console.log(error);
        });
      // this.$store.dispatch("downloadApplicationAction", this.slug)
    }
  },
  created() {
    this.slug = this.$route.params.slug;
    this.showMortgageDetail();

  }
};
</script>

<style scoped>
@import url("../../../assets/css/mortgage-detail.css");
.loader {
  margin: auto;
  top: 25%;
}
a,
h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
span {
  text-rendering: geometricPrecision;
}
.span-parent span {
  font-size: 12px;
  color: #bbbbbb;
  font-weight: 800;
}
.span-parent h4 {
  font-size: 14px;
  color: #555555;
  font-weight: 600;
  margin: 5px auto;
  max-width: 360px;
  line-height: 1.5;
  margin-bottom: 20px;
}
.fp-mortgage-application-lender-info {
  padding: 10px 20px;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.fp-mortgage-application-client-doc {
  background: #fff;
  padding: 10px 15px;
  border-radius: 20px;
}
.fp-mortgage-lender-company-info {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.fp-mortgage-lender-company-info-wrap {
  display: flex;
  justify-content: flex-start;
  padding-top: 15px;
}

.fp-mortgage-application-loan-value {
  padding: 40px 20px;
  background: #022f8f;
  border-radius: 10px;
}
.fp-mortgage-application-loan-value h2 {
  font-size: 30px;
  color: #ffffff;
  /* margin: auto 10px; */
  font-weight: 800;
}
.fp-mortgage-application-loan-value p {
  font-size: 15px;
  color: #ffffff;
  margin: auto 2px;
  /*text-align: left;*/
}
/*col-md-8*/
.fp-mortgage-application-loan-info-display {
  padding: 30px 30px;
  background: #ffffff;
  border-radius: 10px;
}
.fp-mortgage-application-loan-info-display
  .fp-mortgage-application-reference-no-loan-status {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-bottom: 10px;
  border-bottom: 2px dotted #f0f4f7;
}

.fp-mortgage-application-reference-no-loan-status
  .fp-mortgage-application-date {
  margin: 0 180px 0px 0px;
  /* bottom: 0; */
}
.fp-mortgage-loan-amount-repayment-interest-rate {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 15px 0;
  border-bottom: 2px dotted #f0f4f7;
}
.fp-mortgage-loan-amount-repayment-interest-rate
  .fp-mortgage-loan-amount-wrap
  h2 {
  font-size: 30px;
  font-weight: 600;
  color: #54af19;
  margin-bottom: -4px;
}

.fp-mortgage-loan-amount-repayment-interest-rate .fp-mortgage-repayment h2,
.fp-mortgage-loan-amount-repayment-interest-rate
  .fp-mortgage-loan-interest-rate
  h2 {
  font-size: 30px;
  color: #555555;
  font-weight: 600;
  margin-bottom: -4px;
}
.fp-mortgage-loan-more-information {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  border-bottom: 2px dotted #f0f4f7;
}
.fp-mortgage-loan-more-information .fp-mortgage-loan-type-application-wrap {
  display: flex;
  /* justify-content: right; */
  flex-direction: row;
  margin-bottom: 10px;
}
.fp-mortgage-loan-type-application-wrap .fp-mortgage-loan-application-type {
  padding: 6px 20px;
  background: #bbbbbb;
  border-radius: 0.25rem;
  margin-left: 80px;
  height: 30px;
  opacity: 0.5;
}
.fp-mortgage-loan-type-application-wrap .fp-mortgage-loan-type span {
  font-size: 12px;
  color: #bbbbbb;
  font-weight: 800;
}
.fp-mortgage-loan-more-information-left {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 0 10px;
}
.fp-mortgage-loan-more-information-left .fp-mortgage-loan-requirement-status {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: -60px;
}
.fp-mortgage-loan-more-information-left
  .fp-mortgage-loan-requirement-status
  h4 {
  width: 100px;
  font-size: 14px;
  color: #555555;
  font-weight: 600;
  margin: auto 10px;
}
.fp-mortgage-loan-documents-uploads-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding: 10px 0;
}
.fp-mortgage-loan-documents-uploads-actions
  .fp-mortgage-loan-documents-uploads-actions-right {
  display: flex;
  justify-content: space-between;
}
.fp-mortgage-loan-documents-uploads .fp-document-badge {
  height: 15px;
  padding: 3px 8px;
  font-size: 8px;
  border-radius: 5px;
  align-items: center;
  margin-left: -20px;
  margin-top: 10px;
  font-weight: 800;
}
</style>
