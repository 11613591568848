<template>
  <div @click="showDialog = true">
    <v-icon size="50">mdi-file-outline</v-icon>
    <span class="fp-document-badge badge badge-primary">
      {{
      file.doc_name
      }}
    </span>

    <v-dialog v-model="showDialog" transition="dialog-bottom-transition">
      <v-toolbar color>
        <v-toolbar-title>{{ file.doc_name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
              <!--                            <v-btn dark text @click="dialog = false">Save</v-btn>-->
          <a
            class="text-uppercase align-items-center d-flex"
            :download="`${app_ref} - ${file.doc_name}.${fileType}`.toLowerCase()"
            :href='fileURL'
          >
            <v-btn text color="black">
              download
            </v-btn>
          </a>
        </v-toolbar-items>
        <v-spacer></v-spacer>
        <v-btn icon color="black" @click="showDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <pdf-viewer v-if="fileType === 'pdf'" :fileURL="fileURL" />
      <v-card v-else>
        <img :src="fileURL" :alt="file.doc_name">
      </v-card>

    </v-dialog>
  </div>
</template>

<script>
import PDFViewer from "./PDFViewer";

export default {
  props: ["file", 'app_ref'],
  components: {
    "pdf-viewer": PDFViewer
  },
  data() {
    return {
      showDialog: false,
      fileURL: ''
    };
  },
  computed: {
    fileType() {
      const { length, [length - 1]: fileExt } = this.file.filename.split('.');
      return fileExt;
    }
  },
  async created() {
    const file = this.file;
    try {
      const blob = await fetch(`https://cors-anywhere.herokuapp.com/${file.filename}`)
        .then((res) => res.blob());
      const fileURL = URL.createObjectURL(blob);
      this.fileURL = fileURL;
    } catch (error) {
      console.log(error.message);
    }
  },
  beforeDestroy() {
    URL.revokeObjectURL(this.fileURL);
  }
};
</script>
